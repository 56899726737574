
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import debounce from "lodash/debounce";
import { PosFavoriteController } from "@/app/ui/controllers/PosFavoriteController";
import EditModal from "./components/edit-discount.vue";
import EditDefaultSetting from "./components/default-setting.vue";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { PosFavoriteSubscriberListData } from "@/domain/entities/PosFavorite";
import { AuthAlgoController } from "@/app/ui/controllers/AuthAlgoController";

@Options({
  components: {
    EmptyState,
    EditModal,
    EditDefaultSetting
  }
})
export default class MemberTabs extends Vue {
  mounted() {
    PosFavoriteController.initFetchPosFavoriteSubscriber();
  }

  fetchListPosFavoriteSubscriber() {
    PosFavoriteController.fetchListPosFavoriteSubscriber();
  }
  get isLoading() {
    return PosFavoriteController.isLoading || AuthAlgoController.isLoading;
  }

  get errorCause() {
    return PosFavoriteController.errorCause;
  }

  get isError() {
    return PosFavoriteController.isError;
  }

  get isFirstRequest() {
    return PosFavoriteController.isFirstRequest;
  }

  // Table
  get pagination() {
    return PosFavoriteController.posFavoriteSubscriber.pagination;
  }

  columns = [
    {
      name: "No.",
      styleHead: "w-16 text-left whitespace-no-wrap",
      render: (item: PosFavoriteSubscriberListData, index: number) => {
        return `<div class='text-left text-black-lp-300 flex justify-center'>${index +
          1}</div>`;
      }
    },
    {
      name: "Nama Member",
      styleHead: "w-56 text-left whitespace-no-wrap",
      render: (item: PosFavoriteSubscriberListData) => {
        return `<p class="overflow-ellipsis text-black-lp-300 break-all">${item.fullname}</p>`;
      }
    },
    {
      name: "Alamat",
      styleHead: "w-50 text-left whitespace-no-wrap",
      render: () => {
        return `<div class="overflow-ellipsis text-black-lp-300">-</div>`;
      }
    },
    {
      name: "No. Telp",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: PosFavoriteSubscriberListData) => {
        return `<div class='flex text-black-lp-300'>${item.phoneNumber}</div>`;
      }
    },
    {
      name: "Diskon",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: (item: PosFavoriteSubscriberListData) => {
        return `<span class="text-black-lp-300">${item.discount}%</span>`;
      }
    },
    {
      name: "Tanggal Berlangganan",
      styleHead: "w-52 text-left whitespace-no-wrap",
      render: () => {
        return `<div class="overflow-ellipsis text-left">-</div>
        <div class="flex text-gray-lp-500">-</div>`;
      }
    },
    {
      name: "Updated at",
      styleHead: "w-48 text-left whitespace-no-wrap",
      render: () => {
        return `<div class='flex text-black-lp-300'>-</div>
        <div class='flex text-gray-lp-500'>-</div>`;
      }
    },
    {
      name: "Spending",
      styleHead: "w-50 text-left whitespace-no-wrap",
      render: (item: PosFavoriteSubscriberListData) => {
        return `<div class='flex text-black-lp-300'>${formatPrice(
          item.spending
        )}</div>`;
      }
    },
    {
      name: "Action",
      key: "button_column",
      styleHead: "w-32 text-left",
      styleButton: (item: any, index: any) => {
        return {
          class: "w-full bg-white",
          icon: "union-edit",
          outline: true,
          color: "white",
          clickFunction: () => this.setModalShow(item, index)
        };
      }
    }
  ];

  get members() {
    return PosFavoriteController.posFavoriteSubscriber.data;
  }

  // handle Edit Modal
  get isModalShow() {
    return PosFavoriteController.isShowModal;
  }
  setModalShow(item: any, index: any) {
    PosFavoriteController.setShowModal(true);
    PosFavoriteController.setIndexMembers(index);
    PosFavoriteController.setDiscount(item.discount);
  }

  setCloseModalShow() {
    PosFavoriteController.setShowModal(false);
  }

  // search filter
  searchValue = "";
  onSearch = debounce(val => {
    this.searchValue = val;
    if (val.length >= 3 && val !== "") {
      PosFavoriteController.setSearch(val);
      this.fetchListPosFavoriteSubscriber();
    }
  }, 250);
  clearSearch() {
    this.searchValue = "";
    PosFavoriteController.setSearch("");
    this.fetchListPosFavoriteSubscriber();
  }

  // Update
  isUpdateDefaultSetting = false;
  setUpdateDefaultSetting(value: boolean) {
    this.isUpdateDefaultSetting = value;
  }
}
