import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pt-4 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex z-20 items-center justify-between my-4 mb-2" }
const _hoisted_3 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Select = _resolveComponent("Select")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_Search, {
          onInput: _ctx.onSearch,
          onClear: _ctx.clearSearch,
          value: _ctx.searchValue,
          placeholder: "Cari"
        }, null, 8, ["onInput", "onClear", "value"]),
        _createVNode(_component_Select, {
          dataSelect: _ctx.invoiceStatusData,
          onOpenSelect: _ctx.onOpenInvoiceStatusSelect,
          onCloseSelect: _ctx.onCloseInvoiceStatusSelect,
          onChooseSelect: _ctx.onSelectInvoiceStatus,
          value: _ctx.invoiceStatus,
          isOpenMenu: _ctx.invoiceStatusSelect,
          class: 'w-48'
        }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
      ])
    ]),
    (_ctx.outStandMembers.length === 0 && _ctx.isFirstRequest && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_EmptyState, { key: 0 }))
      : (_openBlock(), _createBlock(_component_TableV2, {
          key: 1,
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
          onRequest: _ctx.onRequest,
          loading: _ctx.isLoading,
          columns: _ctx.columns,
          data: _ctx.outStandMembers,
          class: "my-2",
          errorCause: _ctx.errorCause,
          borderTop: false,
          showRightShadow: true
        }, null, 8, ["pagination", "onRequest", "loading", "columns", "data", "errorCause"]))
  ]))
}