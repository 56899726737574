
import { Options, Vue } from "vue-class-component";
import CustomDrowpdown from "@/app/ui/components/custom-dropdown/index.vue";
import RadioButton from "primevue/radiobutton";

@Options({
  emits: ["change", "update:modelValue", "update:discountValue"],
  components: {
    CustomDrowpdown,
    RadioButton
  },
  props: {
    placeholder: {
      default: "Pilih",
      type: String
    },
    modelValue: {
      default: {},
      required: true
    },
    discountValue: {
      default: 0,
      requires: true
    }
  }
})
export default class DiscountDropdown extends Vue {
  props: any = this.$props;

  dropdownDiscountOpened = true;

  get selectedValue() {
    if (this.radioValue === "Default") {
      return [{ name: "Default", discount: 0 }];
    } else {
      return [
        {
          name: "Custom",
          discount: this.props.modelValue.discount
        }
      ];
    }
  }

  get parseDefaultValue() {
    return this.props.modelValue.find(
      (item: any) => item.name.toLowerCase() === "default"
    )
      ? "Default"
      : "Custom";
  }
  get watchModelValue() {
    this.radioValue =
      this.props.modelValue.length > 0 ? this.parseDefaultValue : "Custom";
    return "";
  }
  //radio
  radioValue = "";

  onChange() {
    this.$emit("change", this.selectedValue);
    this.$emit("update:modelValue", this.selectedValue);
  }

  onChangeValueDiscount(e: any) {
    this.$emit("change", e);
    this.$emit("update:discountValue", e);
  }
}
