import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  PosFavoriteSubscriberEntities,
  PosFavoriteSubscriberOutstandingEntities,
  PosFavouriteDefaultDiscount
} from "@/domain/entities/PosFavorite";
import { PosFavoritePresenter } from "@/app/ui/presenters/PosFavoritePresenter";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { AuthAlgoController } from "./AuthAlgoController";
import {
  EditMemberDiscountApiRequest,
  EditDefaultDiscountApiRequest
} from "@/data/payload/api/PosFavoriteApiRequest";
import { getAgentCode } from "@/app/infrastructures/misc/Cookies";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface PosFavoriteState {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  errorCause: string;
  paginationMember: any;
  isLoadingEditDefaultDiscount: boolean;
  isErrorEditDefaultDiscount: boolean;
  isSuccessEditDefaultDiscount: boolean;
  errorCauseEditDefaultDiscount: string;
  isLoadingDetailDefaultDiscount: boolean;
  detailDefaultDiscount: PosFavouriteDefaultDiscount;
  isErrorDetailDefaultDiscount: boolean;
  errorCauseDetailDefaultDiscount: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "pos-favourite"
})
class PosFavoriteStore extends VuexModule implements PosFavoriteState {
  public isLoading = false;
  public isError = false;
  public isSuccess = false;
  public isFirstRequest = true;
  public errorCause = "";
  public search = "";
  public isShowModal = false;
  public isShowModalSuccess = false;
  public indexMembers = 0;
  public filterStatus = "";
  public discount = 0;
  public cacheDiscount = "";
  public posFavoriteSubscriber = new PosFavoriteSubscriberEntities(
    new Pagination(1, 20),
    []
  );
  public posFavoriteSubscriberOutstanding = new PosFavoriteSubscriberOutstandingEntities(
    new Pagination(1, 10),
    []
  );
  public paginationMember = {
    page: 1,
    limit: 20
  };
  public isLoadingEditDefaultDiscount = false;
  public isErrorEditDefaultDiscount = false;
  public isSuccessEditDefaultDiscount = false;
  public errorCauseEditDefaultDiscount = "";
  public isLoadingDetailDefaultDiscount = false;
  public detailDefaultDiscount = new PosFavouriteDefaultDiscount(0, "", "");
  public isErrorDetailDefaultDiscount = false;
  public errorCauseDetailDefaultDiscount = "";

  @Action
  public async getListPosFavoriteSubscriberOutstanding(params: {
    agentCode: string;
    phoneNumber: string;
    page: number;
    limit: number;
    status: string;
  }) {
    const authAlgo = await AuthAlgoController.loginCheckAuthAlgo();
    this.setLoading(true);
    if (authAlgo) {
      const presenter = container.resolve(PosFavoritePresenter);
      presenter
        .getListPosFavoriteSubscriberOutstanding(
          params.agentCode,
          params.phoneNumber,
          params.page,
          params.limit,
          params.status
        )
        .then(res => {
          this.setPosFavoriteSubscriberOutstanding(res);
          this.setError(false);
          this.setErrCause("");
        })
        .catch(err => {
          if (err.response?.data?.message.en === "Invalid phone number") {
            this.resetPosFavoriteSubscriberOutstanding();
          } else {
            this.setError(true);
            this.setErrCause(err.response ? "server" : "internet");
          }
        })
        .finally(() => this.setLoading(false));
    }
  }

  @Action
  public async initFetchPosFavoriteSubscriberOutstanding() {
    this.setFirstRequest(true);
    this.setSearch("");
    this.initPagination();
    await this.getListPosFavoriteSubscriberOutstanding({
      agentCode: getAgentCode(),
      phoneNumber: "",
      page: this.posFavoriteSubscriberOutstanding.pagination.page,
      limit: this.posFavoriteSubscriberOutstanding.pagination.limit,
      status: ""
    });
  }

  @Action
  public async fetchListPosFavoriteSubscriberOutstanding() {
    this.setFirstRequest(false);
    await this.getListPosFavoriteSubscriberOutstanding({
      agentCode: getAgentCode(),
      phoneNumber: this.search,
      page: this.posFavoriteSubscriberOutstanding.pagination.page,
      limit: this.posFavoriteSubscriberOutstanding.pagination.limit,
      status: this.filterStatus
    });
  }

  @Action
  public initFetchPosFavoriteSubscriber() {
    this.setSearch("");
    this.initPagination();
    this.fetchListPosFavoriteSubscriber();
  }

  @Action
  public async fetchListPosFavoriteSubscriber() {
    this.getListPosFavoriteSubscriber({
      agentCode: getAgentCode(),
      phoneNumber: this.search,
      page: this.posFavoriteSubscriber.pagination.page,
      limit: this.posFavoriteSubscriber.pagination.limit
    });
  }

  @Action
  public async getListPosFavoriteSubscriber(params: {
    agentCode: string;
    phoneNumber: string;
    page: number;
    limit: number;
  }) {
    const authAlgo = await AuthAlgoController.loginCheckAuthAlgo();
    this.setLoading(true);
    if (authAlgo) {
      const presenter = container.resolve(PosFavoritePresenter);
      presenter
        .getListPosFavoriteSubscriber(
          params.agentCode,
          params.phoneNumber,
          params.page,
          params.limit
        )
        .then(res => {
          this.setPosFavoriteSubscriber(res);
          this.setError(false);
          this.setErrCause("");
        })
        .catch(err => {
          if (err.response?.data?.message.en === "Invalid phone number") {
            this.resetPosFavoriteSubscriber();
          } else {
            this.setError(true);
            this.setErrCause(err.response ? "server" : "internet");
          }
        })
        .finally(() => this.setLoading(false));
    }
  }

  @Action
  public editMemberDiscount(params: {
    customerId: number;
    agentCode: string;
    discount: number;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(PosFavoritePresenter);
    return presenter
      .editMemberDiscount(
        new EditMemberDiscountApiRequest(
          params.customerId,
          params.agentCode,
          params.discount
        )
      )
      .then(() => {
        this.setShowModalSuccess(true);
        return true;
      })
      .catch(error => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.editMemberDiscount(params);
          })
        );
        this.setShowModalSuccess(false);
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public editDefaultDiscount(params: {
    agentCode: string;
    defaultDiscount: number;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(PosFavoritePresenter);
    return presenter
      .editDefaultDiscount(
        new EditDefaultDiscountApiRequest(
          params.agentCode,
          params.defaultDiscount
        )
      )
      .then(() => {
        this.setSuccessEditDefaultDiscount(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.editDefaultDiscount(params);
          })
        );
        this.setSuccessEditDefaultDiscount(false);
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async getDetailDefaultDiscount(params: { agentCode: string }) {
    this.setLoadingDetailDefaultDiscount(true);
    const presenter = container.resolve(PosFavoritePresenter);
    const authAlgo = await AuthAlgoController.loginCheckAuthAlgo();
    if (authAlgo)
      presenter
        .getDetailDefaultDiscount(params.agentCode)
        .then((res: PosFavouriteDefaultDiscount) => {
          this.setDetailDefaultDiscount(res);
          this.setErrorDetailDefaultDiscount(false);
          this.setErrorCauseDetailDefaultDiscount("");
        })
        .catch((error: any) => {
          this.setErrorDetailDefaultDiscount(true);
          this.setErrorCauseDetailDefaultDiscount(
            error.response ? "server" : "internet"
          );
        })
        .finally(() => {
          this.setLoadingDetailDefaultDiscount(false);
        });
  }

  @Mutation
  public setLoadingDetailDefaultDiscount(status: boolean) {
    this.isLoadingDetailDefaultDiscount = status;
  }

  @Mutation
  public setDetailDefaultDiscount(data: PosFavouriteDefaultDiscount) {
    this.detailDefaultDiscount = data;
  }

  @Mutation
  public setErrorDetailDefaultDiscount(status: boolean) {
    this.isErrorDetailDefaultDiscount = status;
  }

  @Mutation
  public setErrorCauseDetailDefaultDiscount(err: string) {
    this.errorCauseDetailDefaultDiscount = err;
  }

  @Mutation
  initPagination() {
    this.posFavoriteSubscriber.pagination.page = 1;
    this.posFavoriteSubscriberOutstanding.pagination.page = 1;
  }

  @Mutation
  resetPosFavoriteSubscriber() {
    this.posFavoriteSubscriber = new PosFavoriteSubscriberEntities(
      new Pagination(1, 20),
      []
    );
  }

  @Mutation
  resetPosFavoriteSubscriberOutstanding() {
    this.posFavoriteSubscriberOutstanding = new PosFavoriteSubscriberOutstandingEntities(
      new Pagination(1, 20),
      []
    );
  }

  @Mutation
  public setPosFavoriteSubscriber(val: PosFavoriteSubscriberEntities) {
    this.posFavoriteSubscriber = val;
  }

  @Mutation
  public setPosFavoriteSubscriberOutstanding(
    val: PosFavoriteSubscriberOutstandingEntities
  ) {
    this.posFavoriteSubscriberOutstanding = val;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setFilterStatus(val: string) {
    this.filterStatus = val;
  }

  @Mutation
  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @Mutation
  public setLoadingEditDefaultDiscount(val: boolean) {
    this.isLoadingEditDefaultDiscount = val;
  }

  @Mutation
  public setError(err: boolean) {
    this.isError = err;
  }

  @Mutation
  public setErrorEditDefaultDiscount(val: boolean) {
    this.isErrorEditDefaultDiscount = val;
  }

  @Mutation
  public setSuccess(val: boolean) {
    this.isSuccess = val;
  }

  @Mutation
  public setSuccessEditDefaultDiscount(val: boolean) {
    this.isSuccessEditDefaultDiscount = val;
  }

  @Mutation
  public setErrCause(errCause: string) {
    this.errorCause = errCause;
  }

  @Mutation
  public setErrCauseEditDefaultDiscount(err: string) {
    this.errorCauseEditDefaultDiscount = err;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.isFirstRequest = value;
  }

  @Mutation
  public setShowModal(value: boolean) {
    this.isShowModal = value;
  }

  @Mutation
  public setShowModalSuccess(value: boolean) {
    this.isShowModalSuccess = value;
  }

  @Mutation
  public setIndexMembers(value: number) {
    this.indexMembers = value;
  }

  @Mutation
  public setDiscount(value: number) {
    this.discount = value;
  }

  @Mutation
  public setCacheDiscount(value: string) {
    this.cacheDiscount = value;
  }
}

export const PosFavoriteController = getModule(PosFavoriteStore);
