import { LoginAlgoRequestInterface } from "@/data/payload/contracts/AuthAlgoRequest";

export class LoginAlgoApiRequest implements LoginAlgoRequestInterface {
  username: string;
  password: string;
  role: string;

  constructor(username: string, password: string, role: string) {
    this.username = username;
    this.password = password;
    this.role = role;
  }
  toJSON(): string {
    return JSON.stringify({
      username: this.username,
      password: this.password,
      role: this.role
    });
  }
}
