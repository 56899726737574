import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row py-4" }
const _hoisted_2 = { class: "w-13/20 px-6 space-y-6" }
const _hoisted_3 = { class: "w-7/20 border-l border-gray-400 px-6" }
const _hoisted_4 = { class: "flex flex-row justify-end items-center space-x-4 py-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_ModalForm = _resolveComponent("ModalForm")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_ModalForm, {
      title: "Default Discount Settings",
      onClose: _ctx.onCloseUpdate,
      modalWidth: "w-2/5",
      isFooter: false,
      isScroll: false
    }, {
      content: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_DataWrapper, {
              label: "Diskon",
              class: "col-span-2 w-1/2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_LpInput, {
                  modelValue: _ctx.dataDetail.defaultDiscount,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.dataDetail.defaultDiscount = $event)),
                  type: "number",
                  suffix: "%",
                  error: _ctx.dataDetail.defaultDiscount > 25,
                  errorCaption: "Maks. 25 %",
                  errorIcon: false
                }, null, 8, ["modelValue", "error"])
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_DataWrapper, {
              label: "Terakhir Diubah",
              value: _ctx.getUpdatedBy,
              caption: _ctx.dataDetail.updatedBy
            }, null, 8, ["value", "caption"])
          ])
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_LpButton, {
            onClick: _ctx.onCloseUpdate,
            customClass: "px-10 py-2",
            textColor: "red-lp-300",
            title: "Batal",
            outline: ""
          }, null, 8, ["onClick"]),
          _createVNode(_component_LpButton, {
            disabled: _ctx.isDisabled,
            onClick: _ctx.onConfirm,
            customClass: "px-10 py-2",
            textColor: "white",
            title: "Simpan"
          }, null, 8, ["disabled", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["onClose"]), [
      [_vShow, _ctx.modelValue]
    ]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.onSave,
      onClose: _ctx.onCloseConfirmation,
      title: "Konfirmasi Perubahan",
      message: `Anda yakin ingin menyimpan perubahan default discount ?`,
      image: "are-you-sure",
      textSuccess: "Simpan",
      textCancel: "Kembali",
      class: "px-8"
    }, null, 8, ["onSubmit", "onClose"]), [
      [_vShow, _ctx.isConfirmation]
    ]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.onCloseSuccess,
      title: "Perubahan Disimpan",
      message: `Detail default discount berhasil diubah.`,
      image: "image-modal-success",
      textSuccess: "Oke",
      class: "px-8"
    }, null, 8, ["onSubmit", "message"]), [
      [_vShow, _ctx.isSuccess]
    ])
  ], 64))
}