
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import debounce from "lodash/debounce";
import { PosFavoriteController } from "@/app/ui/controllers/PosFavoriteController";

@Options({
  components: {
    EmptyState
  }
})
export default class OutstandingMemberTabs extends Vue {
  mounted() {
    PosFavoriteController.initFetchPosFavoriteSubscriberOutstanding();
  }

  onRequest() {
    PosFavoriteController.fetchListPosFavoriteSubscriberOutstanding();
  }

  get isLoading() {
    return PosFavoriteController.isLoading;
  }

  get errorCause() {
    return PosFavoriteController.errorCause;
  }

  get isFirstRequest() {
    return PosFavoriteController.isFirstRequest;
  }

  get outStandMembers() {
    return PosFavoriteController.posFavoriteSubscriberOutstanding.data;
  }

  //  filter status
  invoiceStatusSelect = false;
  invoiceStatusData = [
    { name: "Filter", value: "" },
    { name: "Unpaid", value: "UNPAID" },
    // API do not support this filter, commented until api enhancement
    // { name: "Unpaid (>= 3 hari)", value: "UNPAID_3_DAYS" },
    // { name: "Unpaid (>= 7 hari)", value: "UNPAID_7_DAYS" },
    // { name: "Unpaid (>= 30 hari)", value: "UNPAID_30_DAYS" },
    // { name: "Unpaid (>= 90 hari)", value: "UNPAID_90_DAYS" },
    { name: "Paid", value: "PAID" }
  ];
  onOpenInvoiceStatusSelect() {
    this.invoiceStatusSelect = true;
  }
  onCloseInvoiceStatusSelect() {
    this.invoiceStatusSelect = false;
  }
  get invoiceStatus() {
    return PosFavoriteController.filterStatus;
  }
  onSelectInvoiceStatus(name: string, value: string) {
    this.onCloseInvoiceStatusSelect();
    PosFavoriteController.setFilterStatus(value);
    PosFavoriteController.initPagination();
    PosFavoriteController.fetchListPosFavoriteSubscriberOutstanding();
  }

  // Table
  get pagination() {
    return PosFavoriteController.posFavoriteSubscriberOutstanding.pagination;
  }

  columns = [
    {
      name: "No.",
      styleHead: "w-20 text-left whitespace-no-wrap",
      render: (item: any, index: number) => {
        return `<div class='text-left text-black-lp-300 flex justify-center'>${index +
          1}</div>`;
      }
    },
    {
      name: "Invoice ID",
      styleHead: "w-42 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-black-lp-300">${item.invoiceId}</div>`;
      }
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-64 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-black-lp-300">${item.createdAt}</div>`;
      }
    },
    {
      name: "Total Invoice",
      styleHead: "w-20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.totalInvoice}</div>`;
      }
    },
    {
      name: "Status Invoice",
      styleHead: "w-64 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="rounded-lg px-2 text-13px text-left font-medium capitalize ${
          item.statusInvoice === "paid"
            ? "bg-green-lp-300 text-green-lp-200"
            : "bg-red-300 text-red-lp-200"
        }">${item.statusInvoice}</div>`;
      }
    },
    {
      name: "Total STT",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left">${item.totalStt}</div>`;
      }
    },
    {
      name: "Nama Member",
      styleHead: "w-52 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left">${item.customerName}</div>`;
      }
    },
    {
      name: "Alamat",
      styleHead: "w-56 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.customerAddress}</div>`;
      }
    },
    {
      name: "No Telp",
      styleHead: "w-30 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.customerPhoneNumber}</div>`;
      }
    }
  ];

  // search filter
  get searchValue() {
    return PosFavoriteController.search;
  }
  onSearch = debounce(val => {
    PosFavoriteController.setSearch(val);
    if (val.length < 3 && val !== "") {
      return;
    }
    PosFavoriteController.fetchListPosFavoriteSubscriberOutstanding();
  }, 250);
  clearSearch() {
    PosFavoriteController.setSearch("");
    PosFavoriteController.initPagination();
    PosFavoriteController.fetchListPosFavoriteSubscriberOutstanding();
  }
}
