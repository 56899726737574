
import { Vue, Options } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import { PropType } from "@vue/runtime-core";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { PosFavoriteController } from "@/app/ui/controllers/PosFavoriteController";
import { formatDate } from "@/app/infrastructures/misc/Utils";
import { getAgentCode } from "@/app/infrastructures/misc/Cookies";

@Options({
  components: {
    ModalForm,
    UploadFile
  },
  props: {
    modelValue: {
      required: true
    },
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    }
  },
  emits: ["update:modelValue"]
})
export default class UpdateModal extends Vue {
  props: any = this.$props;

  mounted() {
    this.getDetail();
  }

  getDetail() {
    PosFavoriteController.getDetailDefaultDiscount({
      agentCode: getAgentCode()
    });
  }

  get dataDetail() {
    return PosFavoriteController.detailDefaultDiscount;
  }

  get getUpdatedBy() {
    return formatDate(this.dataDetail.lastUpdateDate);
  }

  isConfirmation = false;
  onConfirm() {
    this.isConfirmation = true;
    this.props.onClose();
  }

  onCloseConfirmation() {
    this.isConfirmation = false;
    this.$emit("update:modelValue", true);
  }

  async onSave() {
    this.isConfirmation = false;
    await PosFavoriteController.editDefaultDiscount({
      agentCode: getAgentCode(),
      defaultDiscount: Number(this.dataDetail.defaultDiscount)
    });
    this.getDetail();
  }

  get isSuccess() {
    return PosFavoriteController.isSuccessEditDefaultDiscount;
  }

  onBack() {
    this.$emit("update:modelValue", true);
  }

  onCloseUpdate() {
    this.props.onClose();
  }

  onCloseSuccess() {
    PosFavoriteController.setSuccessEditDefaultDiscount(false);
  }

  // Quatifer
  get quantiferList() {
    return [
      {
        value: "",
        name: "Pilih"
      },
      {
        value: "Q-1001",
        name: "Shipping Surcharge"
      },
      {
        value: "Q-1002",
        name: "Publish Rate"
      },
      {
        value: "Q-1003",
        name: "Shipping Surcharge + Publish Rate"
      }
    ];
  }

  get isDisabled(): boolean {
    return (
      this.dataDetail.defaultDiscount > 25 ||
      !this.dataDetail.defaultDiscount ||
      !this.dataDetail.defaultDiscount
    );
  }
}
