
import { Options, Vue } from "vue-class-component";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";

@Options({
  components: {}
})
export default class PosFavourite extends Vue {
  // Tabs
  tabs = [
    { name: "member", title: "Member" },
    { name: "outstanding-member", title: "Tagihan Member" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });

    const eventName =
      value === "member"
        ? "pos_langganan_member"
        : "pos_langganan_tagihan_member";
    GTMCommonEvent(eventName);
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
