/* eslint-disable @typescript-eslint/camelcase */
import {
  EditMemberDiscountRequestInterface,
  EditDefaultDiscountRequestInterface
} from "../contracts/PosFavoriteRequest";

export class EditMemberDiscountApiRequest
  implements EditMemberDiscountRequestInterface {
  private customerId: number;
  private agentCode: string;
  private discount: number;

  constructor(customerId: number, agentCode: string, discount: number) {
    this.customerId = customerId;
    this.agentCode = agentCode;
    this.discount = discount;
  }

  public toJSON() {
    return JSON.stringify({
      customer_id: this.customerId,
      agent_code: this.agentCode,
      discount: this.discount
    });
  }
}

export class EditDefaultDiscountApiRequest
  implements EditDefaultDiscountRequestInterface {
  private agentCode: string;
  private defaultDiscount: number;

  constructor(agentCode: string, defaultDiscount: number) {
    this.agentCode = agentCode;
    this.defaultDiscount = defaultDiscount;
  }

  public toJSON() {
    return JSON.stringify({
      agent_code: this.agentCode,
      default_discount: this.defaultDiscount
    });
  }
}
