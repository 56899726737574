import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { AuthAlgoPresenter } from "../presenters/AuthAlgoPresenter";
import { container } from "tsyringe";
import { LoginAlgoApiRequest } from "@/data/payload/api/AuthAlgoApiRequest";
import {
  setCredentialAlgo,
  getTokenAlgo,
  getExpiredAlgo
} from "@/app/infrastructures/misc/Cookies";

export interface AuthAlgoControllerState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "auth-algo"
})
class AuthAlgoStore extends VuexModule implements AuthAlgoControllerState {
  private algoUsername: any = process.env.VUE_APP_ALGO_GENESIS_USERNAME;
  private algoPassword: any = process.env.VUE_APP_ALGO_GENESIS_PASSWORD;
  isLoading = false;
  isError = false;
  errorCause = "";

  @Action
  public async loginCheckAuthAlgo() {
    this.setLoading(true);
    const presenter = container.resolve(AuthAlgoPresenter);
    const checkAuthAlgo = () => {
      const accessToken = getTokenAlgo();
      const expiredToken = getExpiredAlgo();
      const formatExpireToken = expiredToken
        ? new Date(expiredToken).getTime()
        : 0;
      const checkAuth = accessToken && new Date().getTime() < formatExpireToken;
      return checkAuth ? true : false;
    };
    if (!checkAuthAlgo()) {
      return await presenter
        .loginAlgo(
          new LoginAlgoApiRequest(
            this.algoUsername,
            this.algoPassword,
            "GENESIS"
          )
        )
        .then(res => {
          setCredentialAlgo({ token: res.token, expired: res.expiredAt });
          return true;
        })
        .catch(err => {
          this.setError(true);
          this.setErrorCause(err.response ? "server" : "internet");
          return false;
        })
        .finally(() => this.setLoading(false));
    } else {
      this.setLoading(false);
      return checkAuthAlgo();
    }
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  setErrorCause(val: string) {
    this.errorCause = val;
  }
}

export const AuthAlgoController = getModule(AuthAlgoStore);
