import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-4 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex z-20 items-center justify-between my-4 mb-2" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_EditModal = _resolveComponent("EditModal")!
  const _component_EditDefaultSetting = _resolveComponent("EditDefaultSetting")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_Search, {
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.searchValue,
            placeholder: "Cari No. telp"
          }, null, 8, ["onInput", "onClear", "value"])
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_lp_button, {
            title: "Default Discount Settings",
            textColor: "white",
            onClick: _cache[1] || (_cache[1] = () => this.setUpdateDefaultSetting(true))
          })
        ])
      ]),
      (
        _ctx.members.length === 0 &&
          _ctx.isFirstRequest &&
          !_ctx.isLoading &&
          !_ctx.isError &&
          !_ctx.searchValue &&
          _ctx.pagination.page === 1
      )
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            data: "Pos Favorit"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 1,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.pagination = $event)),
            loading: _ctx.isLoading,
            columns: _ctx.columns,
            data: _ctx.members,
            class: "my-2 w-",
            errorCause: _ctx.errorCause,
            borderTop: false,
            isRightFreeze: true,
            pinnedSubstractHeight: "290px",
            onRequest: _ctx.fetchListPosFavoriteSubscriber
          }, null, 8, ["pagination", "loading", "columns", "data", "errorCause", "onRequest"]))
    ]),
    _createVNode(_component_EditModal, {
      ModalShow: _ctx.isModalShow,
      onClose: _ctx.setCloseModalShow
    }, null, 8, ["ModalShow", "onClose"]),
    _createVNode(_component_EditDefaultSetting, {
      modelValue: _ctx.isUpdateDefaultSetting,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isUpdateDefaultSetting = $event)),
      onClose: () => _ctx.setUpdateDefaultSetting(false)
    }, null, 8, ["modelValue", "onClose"])
  ], 64))
}