
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { PosFavoriteController } from "@/app/ui/controllers/PosFavoriteController";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import DropdownDiscount from "@/app/ui/components/dropdown-discount/index.vue";
import {
  PosFavoriteSubscriberEntities,
  PosFavoriteSubscriberListData
} from "@/domain/entities/PosFavorite";
import { Pagination } from "@/domain/entities/Pagination";
import { getAgentCode } from "@/app/infrastructures/misc/Cookies";

@Options({
  components: {
    Title,
    ModalForm,
    DropdownDiscount
  },
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    ModalShow: {
      default: false,
      type: Boolean
    }
  }
})
export default class extends Vue {
  props: any = this.$props;
  discountValue = [
    {
      name: "Custom",
      discount: this.detailCustomer.discount
    }
  ];

  async _onEdit() {
    //to do on edit
    this.openModal = false;
    const resp = await PosFavoriteController.editMemberDiscount({
      customerId: this.detailCustomer.customerId,
      agentCode: getAgentCode(),
      discount:
        this.discountValue[0].name === "Default"
          ? PosFavoriteController.detailDefaultDiscount.defaultDiscount
          : Number(this.detailCustomer.discount)
    });
    if (resp) PosFavoriteController.fetchListPosFavoriteSubscriber();
    this.resetState();
  }

  get members() {
    return PosFavoriteController.posFavoriteSubscriber.data.length > 0
      ? PosFavoriteController.posFavoriteSubscriber.data
      : new PosFavoriteSubscriberEntities(new Pagination(1, 20), [
          new PosFavoriteSubscriberListData(0, "", "", 0, 0)
        ]).data;
  }

  get indexMembers() {
    return PosFavoriteController.indexMembers;
  }

  inputDiscount(value: any) {
    PosFavoriteController.setDiscount(value);
  }

  get detailCustomer() {
    if (this.members.length > 0) {
      return {
        customerId: this.members[this.indexMembers].customerId,
        fullname: this.members[this.indexMembers].fullname,
        phoneNumber: this.members[this.indexMembers].phoneNumber,
        discount: this.members[this.indexMembers].discount,
        spending: this.members[this.indexMembers].spending
      };
    }
    return {
      customerId: 0,
      fullname: "",
      phoneNumber: "",
      discount: 0,
      spending: 0
    };
  }

  // Save data
  openModal = false;
  openSave() {
    return this.openModal;
  }
  onOpenSave() {
    this.openModal = true;
    this.props.onClose();
  }
  onCloseSave() {
    this.openModal = false;
    this.props.onClose(true);
    this.resetState();
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  get openSuccess() {
    return PosFavoriteController.isShowModalSuccess;
  }

  onCloseSuccess() {
    PosFavoriteController.setShowModalSuccess(false);
    this.resetState();
  }

  resetState() {
    this.discountValue = [
      {
        name: "Custom",
        discount: this.detailCustomer.discount
      }
    ];
  }
}
